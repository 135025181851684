import { useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import Skeleton from "react-loading-skeleton";

import { BalanceOrganizer } from "types";

import { Colors } from "constants/colors";

import {
  GridGeneralReport,
  CardReport,
  Title,
  CircleIcon,
  HiddenResponsive,
  GridGeneralReportResponsive,
} from "./styles";

import { FiChevronRight } from "react-icons/fi";
import cart_line from "assets/ri_shopping-cart-line.svg";
import percent_line from "assets/ri_percent-line.svg";
import time_line from "assets/ri_time-line.svg";
import refund_line from "assets/ri_refund-line.svg";
import wallet_line from "assets/ri_wallet-3-line.svg";
import wallet_line_green from "assets/ri_wallet-3-line-green.svg";

import { ModalFilterByData } from "components/ModalFilterByData";
import { ModalFilterByEvent } from "components/ModalFilterByEvent";
import { ModalFilterByOrganizer } from "components/ModalFilterByOrganizer";
import { ModalFilterByTicket } from "components/ModalFilterByTicket";

import { sortingFilterFinancialGeneral } from "constants/filters";

import { useGeneralContext } from "contexts/GeneralContext";

import { HeaderFilter } from "components/HeaderFilter";

import { ModalTotalPurchases } from "components/ModalTotalPurchases";
import { DrawerTotalPurchases } from "components/DrawerTotalPurchases";
import { ModalGeneralTransfer } from "components/ModalGeneralTransfer";
import { DrawerGeneralTransfer } from "components/DrawerGeneralTransfer";
import { ModalPendingBalance } from "components/ModalPendingBalance";
import { DrawerPendingBalance } from "components/DrawerPendingBalance";
import { ModalGeneralChargeback } from "components/ModalGeneralChargeback";
import { DrawerGeneralChargeback } from "components/DrawerGeneralChargeback";
import { DrawerGeneralBalanceReceivable } from "components/DrawerGeneralBalanceReceivable";
import { useSearchFinancialGeneral } from "hooks/useSearchFinancialGeneral";
import { formatPrice } from "utils/formatPrice";
import { FiltersByDataType } from "components/FiltersByDataType";
import useDefaultFilterByToday from "hooks/useDefaultFilterByToday";
import { ModalFilterByFormPayment } from "components/ModalFilterByFormPayment";
import { useBalanceOrganizer } from "services/Hook/Financial";
import { divideBy100 } from "utils/divideBy100";
import { ModalGeneralBalanceAvailable } from "components/ModalGeneralBalanceAvailable";
import { ModalGeneralBalanceReceivable } from "components/ModalGeneralBalanceReceivable";
import { DrawerGeneralBalanceAvailable } from "components/DrawerGeneralBalanceReceivableAvailable";
import { ModalSuccess } from "components/ModalSuccess";
import { useDisclosure } from "hooks/useDisclosure";
import { LoadingWrapperFilter } from "components/LoadingWrapperFilter";
import { ModalFilterByPurchaseType } from "components/ModalFilterByPurchaseType";

export default function General() {
  useDefaultFilterByToday();

  const { data } = useSearchFinancialGeneral({});
  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure();

  const [searchParams] = useSearchParams();

  const organizer = searchParams.get("organizer") ?? "";

  const { data: dataBalance } = useBalanceOrganizer<BalanceOrganizer>({
    organizerId: organizer,
  });

  const isLoadingBalance = !Boolean(dataBalance) || !Boolean(data);

  const {
    setIsOpenModalTotalPurchases,
    setIsOpenModalGeneralTransfer,
    setIsOpenDrawerGeneralTransfer,
    setIsOpenDrawerTotalPurchases,
    setIsOpenModalPendingBalance,
    setIsOpenDrawerPendingBalance,
    setIsOpenModalGeneralChargeback,
    setIsOpenDrawerGeneralChargeback,
    setIsOpenDrawerGeneralBalanceReceivable,
    setIsOpenModalGeneralBalanceReceivable,
    setIsOpenModalGeneralBalanceAvailable,
    setIsOpenDrawerGeneralBalanceAvailable,
  } = useGeneralContext();

  const MODALS_MAPPER: { [key: string]: any } = {
    total_purchases: () => setIsOpenModalTotalPurchases(true),
    taxs: () => setIsOpenModalGeneralTransfer(true),
    pending: () => setIsOpenModalPendingBalance(true),
    refund: () => setIsOpenModalGeneralChargeback(true),
    balance_receivable: () => setIsOpenModalGeneralBalanceReceivable(true),
    balance_available: () => setIsOpenModalGeneralBalanceAvailable(true),
  };

  const DRAWERS_MAPPER: { [key: string]: any } = {
    total_purchases: () => setIsOpenDrawerTotalPurchases(true),
    taxs: () => setIsOpenDrawerGeneralTransfer(true),
    pending: () => setIsOpenDrawerPendingBalance(true),
    refund: () => setIsOpenDrawerGeneralChargeback(true),
    balance_receivable: () => setIsOpenDrawerGeneralBalanceReceivable(true),
    balance_available: () => setIsOpenDrawerGeneralBalanceAvailable(true),
  };

  const openModal = (key: string) => {
    if (isLoadingBalance) {

    }

    MODALS_MAPPER[key]();
  };

  const openDrawer = (key: string) => {
    DRAWERS_MAPPER[key]();
  };

  const getPriceToPurchase = useCallback(
    (type: "PAID" | "PENDING" | "REFUND", fee?: boolean) => {
      let total = 0;

      const findPrice = data?.find((item) => item._id === type);

      if (!findPrice) {
        return total;
      }

      total = findPrice.total / 100;

      if (fee) {
        total = findPrice.fee / 100;
      }

      return total;
    },
    [data]
  );

  return (
    <LoadingWrapperFilter isLoading={isLoadingBalance}>
      <HiddenResponsive>
        <HeaderFilter filters={sortingFilterFinancialGeneral} />
      </HiddenResponsive>

      <FiltersByDataType />

      <Title>Visão geral</Title>

      <GridGeneralReport>
        <CardReport onClick={() => openModal("total_purchases")}>
          <div className="row">
            <CircleIcon variant="purple">
              <img src={cart_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {data ? (
                <h3>
                  {formatPrice(
                    getPriceToPurchase("PAID") +
                      getPriceToPurchase("PENDING") +
                      getPriceToPurchase("REFUND")
                  )}
                </h3>
              ) : (
                <Skeleton height={24} width={80} />
              )}
              <h4>TOTAL EM VENDAS</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openModal("taxs")}>
          <div className="row">
            <CircleIcon variant="yellow">
              <img src={percent_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {data ? (
                <h3>{formatPrice(getPriceToPurchase("PAID", true))}</h3>
              ) : (
                <Skeleton height={24} width={80} />
              )}

              <h4>TAXAS</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openModal("pending")}>
          <div className="row">
            <CircleIcon variant="orange">
              <img src={time_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {data ? (
                <h3>{formatPrice(getPriceToPurchase("PENDING"))}</h3>
              ) : (
                <Skeleton height={24} width={80} />
              )}

              <h4>PENDENTE</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openModal("refund")}>
          <div className="row">
            <CircleIcon variant="red">
              <img src={refund_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {data ? (
                <h3>{formatPrice(getPriceToPurchase("REFUND"))}</h3>
              ) : (
                <Skeleton height={24} width={80} />
              )}

              <h4>ESTORNOS</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openModal("balance_receivable")}>
          <div className="row">
            <CircleIcon variant="blue">
              <img src={wallet_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {dataBalance ? (
                <h3>
                  {formatPrice(
                    divideBy100(dataBalance?.totalPendingAmount ?? 0)
                  )}
                </h3>
              ) : (
                <Skeleton height={24} width={80} />
              )}

              <h4>SALDO A RECEBER</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openModal("balance_available")}>
          <div className="row">
            <CircleIcon variant="green">
              <img src={wallet_line_green} alt="cart" />
            </CircleIcon>

            <div className="column">
              {dataBalance ? (
                <h3>
                  {formatPrice(
                    divideBy100(dataBalance?.totalAvailableAmount ?? 0)
                  )}
                </h3>
              ) : (
                <Skeleton height={24} width={80} />
              )}

              <h4>SALDO DISPONÍVEL</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>
      </GridGeneralReport>

      <GridGeneralReportResponsive>
        <CardReport onClick={() => openDrawer("total_purchases")}>
          <div className="row">
            <CircleIcon variant="purple">
              <img src={cart_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {data ? (
                <h3>
                  {formatPrice(
                    getPriceToPurchase("PAID") +
                      getPriceToPurchase("PENDING") +
                      getPriceToPurchase("REFUND")
                  )}
                </h3>
              ) : (
                <Skeleton height={18} width={80} />
              )}

              <h4>TOTAL EM VENDAS</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openDrawer("taxs")}>
          <div className="row">
            <CircleIcon variant="yellow">
              <img src={percent_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {data ? (
                <h3>{formatPrice(getPriceToPurchase("PAID", true))}</h3>
              ) : (
                <Skeleton height={18} width={80} />
              )}

              <h4>TAXAS</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openDrawer("pending")}>
          <div className="row">
            <CircleIcon variant="orange">
              <img src={time_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {data ? (
                <h3>{formatPrice(getPriceToPurchase("PENDING"))}</h3>
              ) : (
                <Skeleton height={18} width={80} />
              )}

              <h4>PENDENTE</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openDrawer("refund")}>
          <div className="row">
            <CircleIcon variant="red">
              <img src={refund_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {data ? (
                <h3>{formatPrice(getPriceToPurchase("REFUND"))}</h3>
              ) : (
                <Skeleton height={18} width={80} />
              )}

              <h4>ESTORNOS</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openDrawer("balance_receivable")}>
          <div className="row">
            <CircleIcon variant="blue">
              <img src={wallet_line} alt="cart" />
            </CircleIcon>

            <div className="column">
              {dataBalance ? (
                <h3>
                  {formatPrice(
                    divideBy100(dataBalance?.totalPendingAmount ?? 0)
                  )}
                </h3>
              ) : (
                <Skeleton height={24} width={80} />
              )}

              <h4>SALDO A RECEBER</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>

        <CardReport onClick={() => openDrawer("balance_available")}>
          <div className="row">
            <CircleIcon variant="green">
              <img src={wallet_line_green} alt="cart" />
            </CircleIcon>

            <div className="column">
              {dataBalance ? (
                <h3>
                  {formatPrice(
                    divideBy100(dataBalance?.totalAvailableAmount ?? 0)
                  )}
                </h3>
              ) : (
                <Skeleton height={24} width={80} />
              )}

              <h4>SALDO DISPONÍVEL</h4>
            </div>
          </div>

          <FiChevronRight color={Colors.gray45} size={20} />
        </CardReport>
      </GridGeneralReportResponsive>

      <ModalFilterByData />

      <ModalFilterByEvent />

      <ModalFilterByOrganizer />

      <ModalFilterByTicket />

      <ModalFilterByFormPayment />

      <ModalTotalPurchases
        total={
          getPriceToPurchase("PAID") +
          getPriceToPurchase("PENDING") +
          getPriceToPurchase("REFUND")
        }
        fee={getPriceToPurchase("PAID", true)}
      />

      <DrawerTotalPurchases
        total={
          getPriceToPurchase("PAID") +
          getPriceToPurchase("PENDING") +
          getPriceToPurchase("REFUND")
        }
        fee={getPriceToPurchase("PAID", true)}
      />

      <ModalGeneralTransfer
        total={
          getPriceToPurchase("PAID") +
          getPriceToPurchase("PENDING") +
          getPriceToPurchase("REFUND")
        }
        fee={getPriceToPurchase("PAID", true)}
      />

      <DrawerGeneralTransfer
        total={
          getPriceToPurchase("PAID") +
          getPriceToPurchase("PENDING") +
          getPriceToPurchase("REFUND")
        }
        fee={getPriceToPurchase("PAID", true)}
      />

      <ModalFilterByPurchaseType />

      <ModalPendingBalance
        total={getPriceToPurchase("PENDING")}
        fee={getPriceToPurchase("PAID", true)}
      />

      <DrawerPendingBalance
        total={getPriceToPurchase("PENDING")}
        fee={getPriceToPurchase("PENDING", true)}
      />

      <ModalGeneralChargeback
        total={getPriceToPurchase("REFUND")}
        fee={getPriceToPurchase("REFUND", true)}
      />

      <DrawerGeneralChargeback
        total={getPriceToPurchase("REFUND")}
        fee={getPriceToPurchase("REFUND", true)}
      />

      <ModalGeneralBalanceReceivable
        total={dataBalance?.totalPendingAmount ?? 0}
      />

      <DrawerGeneralBalanceReceivable
        total={dataBalance?.totalPendingAmount ?? 0}
      />

      <ModalGeneralBalanceAvailable
        balance={dataBalance}
        onWithdraw={showModal}
      />

      <DrawerGeneralBalanceAvailable
        onWithdraw={showModal}
        balance={dataBalance}
      />

      <ModalSuccess
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        title="Saque realizado!"
        subtitle="Sua solicitação de saque foi realizada com sucesso! Clique em ”Ok” para prosseguir ou visualize o comprovante."
      />
    </LoadingWrapperFilter>
  );
}
