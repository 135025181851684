import { useState } from "react";
import { getTeamDetail, getTeams } from "services/teams";
import useSWR from "swr";

type TeamListDto = {
  id?: string;
};

export function useTeamsList({ id }: TeamListDto) {
  const [page, setPage] = useState(1);
  const [hasMoreTeams, setHasMoreTeams] = useState(true);
  const url = `/team?page=${page}&limit=10`;

  const { data, error, mutate } = useSWR(url, async () => {
    return getTeams({ id, page });
  });

  const handleLoadMoreTeams = async () => {
    const nextPage = page + 1;
    setPage(nextPage);

    const response = await getTeams({ id, page: nextPage, limit: 10 });

    const newResults = response?.results || [];
    const allResults = [...(data?.results || []), ...newResults];

    const lastPage = Math.ceil(response.count / 10);
    if (nextPage >= lastPage) {
      setHasMoreTeams(false);
    }

    mutate(
      { count: response.count, results: allResults },
      { revalidate: false }
    );
  };

  return {
    data: data ?? { count: 0, results: [] },
    error,
    hasMoreTeams,
    loadMoreTeams: handleLoadMoreTeams,
  };
}

export function useTeamDetail(id?: string) {
  const url: string = `/team/${id}`;

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      if (!id) return;

      const data = await getTeamDetail(id);

      return data;
    },
    {
      isPaused: () => !Boolean(id),
    }
  );

  return { data: data, error, mutate };
}
