import { useState } from "react";

import { FiChevronRight } from "react-icons/fi";
import { RiInformationLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  Column,
  ColumnContent,
  ColumnContentFlexEnd,
  ColumnLineIcon,
  Container,
  ContainerItems,
  ContainerItemsReceipts,
  ContainerLastReceipts,
  ContainerTax,
  ContentFlex,
  Divider,
  Hr,
  ItemReceipt,
  ListReceipts,
  LoadMoreContainer,
  Row,
  RowContent,
  Title,
  TitleContentItem,
  TitleDataSmall,
  TitleItemReceipt,
  TitlePrice,
  TitleTax,
  TitleTotal,
} from "./styles";

import { DrawerOverlay } from "components/DrawerOverlay";

import { useGeneralContext } from "contexts/GeneralContext";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";
import { useSearchFinancialTransaction } from "hooks/useSearchFinancialTransaction";
import { formatPrice } from "utils/formatPrice";
import { Icon, Text } from "components/BadgePurchaseByStatus";
import { Transaction } from "types";
import { Link } from "react-router-dom";
import LoadMoreItems from "components/LoadMoreItems";

interface DrawerTotalPurchasesProps {
  total: number;
  fee: number;
}

export function DrawerTotalPurchases({
  total,
  fee,
}: DrawerTotalPurchasesProps) {
  const [selectedPurchase, setSelectedPurchase] = useState(false);

  const { isOpenDrawerTotalPurchases, setIsOpenDrawerTotalPurchases } =
    useGeneralContext();

  const {
    data,
    handleLoadMorePurchasingsMobile,
    hasMoreItems,
    loadingMoreItems,
  } = useSearchFinancialTransaction({});

  return (
    <DrawerOverlay
      title="Vendas totais"
      icon={
        !selectedPurchase ? (
          <BiChevronDown
            color={Colors.white}
            size={24}
            onClick={() => setIsOpenDrawerTotalPurchases(false)}
          />
        ) : (
          <BiChevronLeft
            color={Colors.white}
            size={24}
            onClick={() => setSelectedPurchase(false)}
          />
        )
      }
      isVisible={isOpenDrawerTotalPurchases}
      handleOk={() => setIsOpenDrawerTotalPurchases(false)}
    >
      {!selectedPurchase ? (
        <Container>
          <ContainerItems>
            <Title>Vendas totais do evento</Title>

            <Divider />

            <Row>
              <Column>
                <TitleTotal>vendas totais</TitleTotal>

                <TitlePrice>{formatPrice(total ?? 0)}</TitlePrice>
              </Column>

              <FiChevronRight color={Colors.gray45} size={24} />
            </Row>

            <ContainerTax>
              <RiInformationLine color={Colors.black50} size={12} />

              <TitleTax>
                Taxa administrativa: <strong>{formatPrice(fee ?? 0)}</strong>{" "}
              </TitleTax>
            </ContainerTax>
          </ContainerItems>

          <ContainerLastReceipts>
            <ContainerItemsReceipts>
              <TitleTotal>ÚLTIMOS RECEBIMENTOS</TitleTotal>

              <ListReceipts>
                {data?.results?.map((purchase: Transaction) => (
                  <Link
                    style={{ width: "100%" }}
                    to={`/financial/${purchase?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="table-link"
                  >
                    <ItemReceipt>
                      <ColumnLineIcon>
                        <Icon status={purchase?.payment_status} />

                        <Hr />
                      </ColumnLineIcon>

                      <RowContent>
                        <ColumnContent>
                          <TitleItemReceipt>
                            {<Text status={purchase?.payment_status} />}
                          </TitleItemReceipt>

                          <TitleContentItem>
                            {purchase?.event_id?.name}
                          </TitleContentItem>
                        </ColumnContent>

                        <ColumnContentFlexEnd>
                          <TitleDataSmall>
                            {purchase?.formattedDate}
                          </TitleDataSmall>

                          <ContentFlex>
                            <TitleContentItem>
                              {formatPrice(
                                purchase?.total ? purchase?.total / 100 : 0
                              )}
                            </TitleContentItem>

                            <FiChevronRight color={Colors.gray45} size={14} />
                          </ContentFlex>
                        </ColumnContentFlexEnd>
                      </RowContent>
                    </ItemReceipt>
                  </Link>
                ))}
              </ListReceipts>
            </ContainerItemsReceipts>
          </ContainerLastReceipts>

          <LoadMoreContainer>
            <LoadMoreItems
              handleLoadMore={handleLoadMorePurchasingsMobile}
              fetching={loadingMoreItems}
              hasMoreItems={hasMoreItems}
              length={data?.results?.length}
              total={data?.count}
            />
          </LoadMoreContainer>
        </Container>
      ) : null}
    </DrawerOverlay>
  );
}
