import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { Tabs } from "antd";

import { RiCloseCircleLine, RiEqualizerLine } from "react-icons/ri";

import { useMyEventDetail } from "services/Hook/Event";

import { Colors } from "constants/colors";

import { Event } from "types";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  HeaderEvent,
  ContainerTabsMain,
  DividerFooter,
  ButtonFilter,
  ContainerFilters,
  ModalStyled,
} from "./styles";

import General from "./components/Tabs/General";
import Transaction from "./components/Tabs/Transaction";

import Popup from "containers/Popup";

import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Footer } from "components/Footer";
import { NeedHelpArticle } from "components/NeedHelpArticle";

import { useFiltersContext } from "contexts/FiltersContext";
import { DrawerFilterGeneral } from "components/DrawerFilterGeneral";
import { DrawerFilterTransaction } from "components/DrawerFilterTransaction";
import { FiltersByTag } from "components/FiltersTag";
import { useGenerateFinancialReport } from "hooks/useGenerateFinancialReport";
import { ModalSuccess } from "components/ModalSuccess";
import GenerateReportButton from "./components/GenerateReportButton";
import ImportPurchasesButton from "./components/ImportPurchasesButton";
import ImportPurchasesModal from "./components/ImportPurchasesModal";
import { useDisclosure } from "hooks/useDisclosure";

const { TabPane } = Tabs;

export default function MyEventFinancial() {
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { handleGenerateReport, handleOk, handleCancel, isModalVisible } =
    useGenerateFinancialReport();

  const {
    handleCancel: handleCancelImport,
    handleOk: handleOkImport,
    isModalVisible: isModalVisibleImport,
    showModal: showModalImport,
  } = useDisclosure();

  const {
    handleCancel: handleCancelUpload,
    handleOk: handleOkUpload,
    isModalVisible: isModalVisibleUpload,
    showModal: showModalUpload,
  } = useDisclosure();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const { id } = useParams();

  const { data } = useMyEventDetail<Event>(id ?? null);

  const { setIsOpenFilterDrawerGeneral, setIsOpenFilterDrawerTransactions } =
    useFiltersContext();

  const tabName = searchParams.get("tab_name") ?? "general";

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const handleActiveTab = (activeKey: string) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      tab_name: activeKey,
    });
  };

  const metaTags = {
    title: `${data?.name ?? "Financeiro"} - ${
      process.env.REACT_APP_EVENTX_META_TITLE_NAME
    }`,
    noIndex: true,
  };

  const handleOpenDrawerFilterGeneral = () => {
    setIsOpenFilterDrawerGeneral(true);
  };

  const handleOpenDrawerFilterTransactions = () => {
    setIsOpenFilterDrawerTransactions(true);
  };

  const handleOpenModalSuccessUpload = () => {
    handleCancelImport();
    showModalUpload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  return (
    <>
      <Header metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="bread">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Financeiro", link: "/financial" },
              ]}
            />
          </div>

          <HeaderEvent>
            <h1>Financeiro</h1>

            <div className="buttons">
              {tabName === "general" ? (
                <ButtonFilter onClick={handleOpenDrawerFilterGeneral}>
                  <RiEqualizerLine size={18} color={Colors.secondary100} />
                </ButtonFilter>
              ) : null}

              {tabName === "transactions" ? (
                <ButtonFilter onClick={handleOpenDrawerFilterTransactions}>
                  <RiEqualizerLine size={18} color={Colors.secondary100} />
                </ButtonFilter>
              ) : null}

              <ImportPurchasesButton onClick={showModalImport} />

              <GenerateReportButton
                onHandleGenerateButton={handleGenerateReport}
              />
            </div>
          </HeaderEvent>

          <ContainerFilters>
            <FiltersByTag />
          </ContainerFilters>

          <ContainerTabsMain>
            <Tabs onChange={handleActiveTab} activeKey={tabName}>
              <TabPane tab="Visão geral" key="general">
                <General />
              </TabPane>

              <TabPane tab="Transações" key="transactions">
                <Transaction />
              </TabPane>
            </Tabs>

            <DividerFooter />

            <NeedHelpArticle />
          </ContainerTabsMain>
        </ContainerItems>
      </Container>

      <DrawerFilterGeneral />

      <DrawerFilterTransaction />

      <ModalSuccess
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        title="Relatório gerado com sucesso"
        subtitle="O relatório está sendo processo e em instantes será enviado para o seu e-mail."
      />

      <ModalSuccess
        handleOk={handleOkUpload}
        handleCancel={handleCancelUpload}
        isModalVisible={isModalVisibleUpload}
        title="Sucesso"
        subtitle="As compras foram importadas com sucesso."
      />

      <ModalStyled
        width={857}
        visible={isModalVisibleImport}
        onOk={handleOkImport}
        onCancel={handleCancelImport}
        style={{ borderRadius: "20px" }}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "34px" }}
            size={43}
            color={Colors.black40}
          />
        }
        footer={null}
      >
        <ImportPurchasesModal onSuccessUpload={handleOpenModalSuccessUpload} />
      </ModalStyled>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
