import { useState } from "react";

import { FiChevronRight } from "react-icons/fi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  ButtonReport,
  CardAccordionMobile,
  Column,
  ColumnContent,
  ColumnContentFlexEnd,
  ColumnLineIcon,
  Container,
  ContainerAccordion,
  ContainerButtonMobile,
  ContainerContent,
  ContainerItems,
  ContainerItemsReceipts,
  ContainerLastReceipts,
  DataDropDownMobile,
  Divider,
  Hr,
  ItemReceipt,
  LableTitle,
  ListReceipts,
  ListSales,
  Row,
  RowContent,
  Sale,
  SelectStyled,
  Title,
  TitleContentItem,
  TitleDataSale,
  TitleDataSmall,
  TitleItemReceipt,
  TitleMonth,
  TitlePriceMonth,
  TitleTotal,
  TitleTotalSale,
  TitleTotalSalePrice,
  BorderSelect,
  LableTitleAccordion,
  TitlePrice,
  ContentFlex,
  LoadMoreContainer,
} from "./styles";

import { Select } from "antd";

import { DrawerOverlay } from "components/DrawerOverlay";
import { GoChevronDown, GoChevronUp } from "react-icons/go";

import report_icon from "assets/report_icon.svg";
import { useGeneralContext } from "contexts/GeneralContext";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";

import { useSearchFinancialTransaction } from "hooks/useSearchFinancialTransaction";
import { formatPrice } from "utils/formatPrice";
import { Link } from "react-router-dom";
import LoadMoreItems from "components/LoadMoreItems";

const { Option } = Select;

interface DrawerPendingBalanceProps {
  total: number;
  fee: number;
}

export function DrawerPendingBalance({
  total,
  fee,
}: DrawerPendingBalanceProps) {
  const [selectedPurchase] = useState<"resume" | "detail" | null>(null);

  const { isOpenDrawerPendingBalance, setIsOpenDrawerPendingBalance } =
    useGeneralContext();

  const {
    data,
    handleLoadMorePurchasingsMobile,
    hasMoreItems,
    loadingMoreItems
  } = useSearchFinancialTransaction({
    statusFinancial: "PENDING",
  });

  const title: { [key: string]: any } = {
    resume: "Resumo das vendas",
    detail: "Detalhes da venda",
    default: "Saldo pendente",
  };

  return (
    <DrawerOverlay
      title={title[selectedPurchase ?? "default"]}
      icon={
        !selectedPurchase ? (
          <BiChevronDown
            color={Colors.white}
            size={24}
            onClick={() => setIsOpenDrawerPendingBalance(false)}
          />
        ) : (
          <BiChevronLeft color={Colors.white} size={24} onClick={() => {}} />
        )
      }
      isVisible={isOpenDrawerPendingBalance}
      handleOk={() => setIsOpenDrawerPendingBalance(false)}
    >
      {selectedPurchase === null ? (
        <Container>
          <ContainerItems>
            <Title>Vendas totais do evento</Title>

            <Divider />

            <Row>
              <Column>
                <TitleTotal>saldo pendente</TitleTotal>

                <TitlePrice>{formatPrice(total ?? 0)}</TitlePrice>
              </Column>

              <FiChevronRight color={Colors.gray45} size={24} />
            </Row>

            {/* <ContainerTax>
              <RiInformationLine color={Colors.black50} size={12} />

              <TitleTax>Aguardando processo de pagamento</TitleTax>
            </ContainerTax> */}
          </ContainerItems>

          <ContainerLastReceipts>
            <ContainerItemsReceipts>
              <TitleTotal>últimas vendas</TitleTotal>

              <ListReceipts>
                {data?.results?.map((purchase) => (
                  <Link
                    style={{ width: "100%" }}
                    to={`/financial/${purchase?._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="table-link"
                  >
                    <ItemReceipt onClick={() => {}}>
                      <ColumnLineIcon>
                        <RiMoneyDollarCircleLine
                          size={8}
                          color={Colors.orange100}
                        />

                        <Hr />
                      </ColumnLineIcon>

                      <RowContent>
                        <ColumnContent>
                          <TitleItemReceipt>
                            Aguardando pagamento
                          </TitleItemReceipt>

                          <TitleContentItem>
                            {purchase?.event_id?.name}
                          </TitleContentItem>
                        </ColumnContent>

                        <ColumnContentFlexEnd>
                          <TitleDataSmall>
                            {purchase?.formattedDate}
                          </TitleDataSmall>

                          <ContentFlex>
                            <TitleContentItem>
                              {formatPrice(
                                purchase?.total ? purchase?.total / 100 : 0
                              )}
                            </TitleContentItem>

                            <FiChevronRight color={Colors.gray45} size={14} />
                          </ContentFlex>
                        </ColumnContentFlexEnd>
                      </RowContent>
                    </ItemReceipt>
                  </Link>
                ))}
              </ListReceipts>
            </ContainerItemsReceipts>
          </ContainerLastReceipts>

          <LoadMoreContainer>
            <LoadMoreItems
              handleLoadMore={handleLoadMorePurchasingsMobile}
              fetching={loadingMoreItems}
              hasMoreItems={hasMoreItems}
              length={data?.results?.length}
              total={data?.count}
            />
          </LoadMoreContainer>
        </Container>
      ) : null}

      {selectedPurchase === "resume" ? (
        <Container>
          <ContainerItems>
            <Title>Resumo das vendas totais</Title>

            <Divider />

            <LableTitle>selecionar ano</LableTitle>

            <BorderSelect>
              <SelectStyled
                size="middle"
                labelInValue
                showSearch
                allowClear
                bordered={false}
                placeholder="Ano"
                style={{
                  width: "100%",
                }}
                onChange={(e: any) => {
                  // onChange(e.key);
                }}
              >
                <Option value="1" key="1">
                  2020
                </Option>

                <Option value="2" key="2">
                  2021
                </Option>
              </SelectStyled>
            </BorderSelect>

            <ContainerAccordion>
              <LableTitleAccordion>vendas por mês</LableTitleAccordion>

              {[0, 1, 2, 3].map((i) => (
                <div key={"ticket.id"} className="accordion">
                  <CardAccordionMobile
                    // active={ticket.isOpen}
                    active
                    onClick={() => {}}
                  >
                    <div className="content">
                      <div className="image">
                        <RiMoneyDollarCircleLine
                          color={Colors.secondary40}
                          size={31}
                        />
                      </div>

                      <div className="texts">
                        <div className="first">
                          <h2 className="text">Abril - 2021</h2>
                        </div>

                        <span>R$3.563,00</span>
                      </div>
                    </div>

                    {true ? (
                      <GoChevronDown size={14} color={Colors.secondary50} />
                    ) : (
                      <GoChevronUp size={14} color={Colors.secondary50} />
                    )}
                  </CardAccordionMobile>

                  {true ? (
                    <DataDropDownMobile>
                      <ContainerContent>
                        <TitleMonth>VENDAS DE ABRIL</TitleMonth>

                        <TitlePriceMonth>R$1.625,00</TitlePriceMonth>
                      </ContainerContent>

                      <ListSales>
                        {[0, 1, 2, 3, 4, 5].map((i) => (
                          <Sale>
                            <TitleDataSale>31 ABR</TitleDataSale>
                            <TitleTotalSale>Total de vendas</TitleTotalSale>
                            <TitleTotalSalePrice>R$162,50</TitleTotalSalePrice>
                          </Sale>
                        ))}
                      </ListSales>

                      <ContainerButtonMobile>
                        <ButtonReport>
                          <img src={report_icon} alt="" />

                          <span>Gerar relatório</span>
                        </ButtonReport>
                      </ContainerButtonMobile>
                    </DataDropDownMobile>
                  ) : null}
                </div>
              ))}
            </ContainerAccordion>
          </ContainerItems>
        </Container>
      ) : null}

      {/* {selectedPurchase === "detail" ? (
        <PurchaseDetail purchase={purchase} />
      ) : //   <ContainerButtons>
      //     <ButtonSecondary>
      //       <span>Entrar em contato com comprador</span>
      //     </ButtonSecondary>

      //     <ButtonPrimary>
      //       <span>Cancelar venda</span>
      //     </ButtonPrimary>
      //   </ContainerButtons>
      // </PurchaseDetail>
      null} */}
    </DrawerOverlay>
  );
}
