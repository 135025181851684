import { Link } from "react-router-dom";

import { BsThreeDots } from "react-icons/bs";

import { Colors } from "constants/colors";
import { Transaction } from "types";

import { Container, Box, DropDownEvent } from "./styles";

import eyes_event_icon from "assets/eyes-event-icon.svg";
import { BadgePurchaseByStatus } from "components/BadgePurchaseByStatus";
import { returnTypePaymentPurchase } from "utils/purchase";
import { formatPrice } from "utils/formatPrice";
import { MdOutlineRemoveModerator } from "react-icons/md";

interface TableTransactionsProps {
  purchases: Transaction[] | null;
  handleOpenDropCardEvent(purchase: Transaction | null): void;
  eventSelected: Transaction | null;
}

export function TableTransactions({
  purchases,
  handleOpenDropCardEvent,
  eventSelected,
}: TableTransactionsProps) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>COMPRADOR</th>
            <th>DATA DA VENDA</th>
            <th>VALOR DA VENDA</th>
            <th>STATUS</th>
            <th>FORMA DE PAGAMENTO</th>
          </tr>
        </thead>

        <tbody>
          {purchases?.map((purchase) => {
            return (
              <tr style={{ borderRadius: 20 }} key={purchase?._id}>
                <td>
                  <div className="row">
                    <div className="column">
                      <Link to={`/financial/${purchase?._id}`}>
                        <h5>{purchase?._id}</h5>
                      </Link>
                    </div>
                  </div>
                </td>

                <td>
                  <div className="date">
                    <h5>{purchase?.user_id?.name}</h5>

                    <h6>{purchase?.user_id?.email}</h6>
                  </div>
                </td>

                <td>
                  <h5>{purchase?.formattedDate}</h5>
                </td>

                <td>
                  <h5>
                    {formatPrice(purchase?.total ? purchase?.total / 100 : 0)}
                  </h5>
                </td>

                <td>
                  <BadgePurchaseByStatus
                    status={purchase?.payment_status ?? ""}
                  />
                </td>

                <td>
                  <h5>{returnTypePaymentPurchase(purchase?.payment_type)}</h5>
                </td>

                <td>
                  <div className="div-icon-three">
                    <Box
                      active={eventSelected?._id === purchase._id}
                      onClick={() => handleOpenDropCardEvent(purchase)}
                    >
                      <BsThreeDots
                        style={{ cursor: "pointer" }}
                        size={22}
                        color={Colors.black100}
                      />
                    </Box>

                    {eventSelected?._id === purchase._id ? (
                      <DropDownEvent>
                        <ul>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/financial/${purchase?._id}`}
                          >
                            <li
                              className={
                                purchase?.payment_status === "PAID"
                                  ? ""
                                  : "last"
                              }
                            >
                              <div className="circle-icon">
                                <img src={eyes_event_icon} alt="eyes" />
                              </div>
                              <span>Ver detalhes</span>
                            </li>
                          </Link>

                          {purchase?.payment_status === "PAID" ? (
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/profile/terms-cancel-by-organizer/${purchase?._id}`}
                            >
                              <li className="last">
                                <div className="circle-icon">
                                  <MdOutlineRemoveModerator
                                    color={Colors.red10}
                                  />
                                </div>
                                <span>Cancelar a compra</span>
                              </li>
                            </Link>
                          ) : null}
                        </ul>
                      </DropDownEvent>
                    ) : null}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}
